<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <div class="row items-center justify-start q-mb-lg">
      <DateRangeSelector
        initialPreset="Today"
        @update="dates => updateDateRange(dates)"
      />
    </div>
    <q-card>
      <div
        class="full-width flex justify-between items-center q-py-sm q-px-md q-header--bordered"
        style="min-height: 60px"
      >
        <h5 class="q-ma-none text-weight-bold">
          Inventory Pipeline Status
        </h5>
        <div class="flex no-wrap items-center q-gutter-x-md">
          <q-btn
            class="q-pa-sm"
            unelevated
            ripple
            icon="refresh"
            flat
            color="grey-6"
            text-color="grey-7"
            size="md"
            @click="generateLineCharts()"
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              {{
                latestRecord
                  ? "Last Updated: " + lastRefreshedFormatted
                  : "Refresh"
              }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
      <q-card-section class="flex items-center justify-start">
        <q-select
          dense
          filled
          v-model="productTypeId"
          label="Product Type"
          :options="productTypeOptions"
          emit-value
          map-options
          style="width: 150px"
          class="q-mr-lg"
        />
        <q-select
          dense
          filled
          v-model="inventorySourceId"
          label="Inventory Source"
          :options="inventorySourceOptions"
          emit-value
          map-options
          @update:model-value="generateLineCharts()"
          style="width: auto;"
          class="q-mr-lg"
        />
        <MetricsList
          v-if="!loading && latestRecord"
          :metrics="[
            {
              label: 'Latest Sync Accuracy',
              value: latestSyncAccuracy + '%'
            },
            {
              label:
                'Database Active ' +
                productTypeOptions.find(o => o.value == productTypeId)
                  .metricLabel,
              value: Intl.NumberFormat().format(
                latestRecord['db_products_active']
              ),
              icon: {
                name: 'circle',
                color: '#1C5ECA'
              }
            },
            {
              label:
                'ElasticSearch Active ' +
                productTypeOptions.find(o => o.value == productTypeId)
                  .metricLabel,
              value: Intl.NumberFormat().format(
                latestRecord['es_products_active']
              ),
              icon: {
                name: 'circle',
                color: '#76A3EC'
              }
            }
          ]"
        />
      </q-card-section>
      <q-card-section>
        <div v-if="loading" class="text-center">
          <q-spinner color="primary" size="3em" />
        </div>
        <div v-else>
          <LineChart
            v-if="series.length > 0"
            :data-series="series"
            :x-axis-labels="labels"
            height="200px"
          />
        </div>
      </q-card-section>
    </q-card>
    <DataTable
      class="q-mt-lg"
      table-key="InventoryPipelineOverview"
      row-key="id"
      title="ElasticSearch Product Sync Log"
      :columns="columns"
      :rows="history"
      :loading="loading"
      :pagination="{
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 25
      }"
      :downloadable="false"
      :refreshable="false"
      :customizable="false"
      :filterable="false"
      :searchable="false"
      :disable-state="true"
    />
  </div>
</template>

<script>
import moment from "moment";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import LineChart from "@/components/UI/LineChart.vue";
import axios from "axios";
import DateRangeSelector from "@/components/UI/DateRangeSelector.vue";
import MetricsList from "@/components/UI/MetricsList.vue";
import DataTable from "@/components/DataTable.vue";
moment.locale("en");

export default {
  name: "InventoryPipelineOverview",
  components: {
    MetricsList,
    DateRangeSelector,
    LineChart,
    PageDynamicBreadcrumb,
    DataTable
  },
  data() {
    return {
      loading: true,
      labels: [],
      series: [],
      columns: [
        {
          name: "id",
          label: "ID",
          field: "id",
          hideField: false,
          type: "dimension",
          sortable: true
        },
        {
          name: "selected",
          label: "Records Selected",
          field: "selected",
          format: val => Intl.NumberFormat().format(parseInt(val)),
          hideField: false,
          type: "dimension",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "synced",
          label: "Records Synced",
          field: "synced",
          format: val => Intl.NumberFormat().format(parseInt(val)),
          hideField: false,
          type: "dimension",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "deleted",
          label: "Records Deleted",
          field: "deleted",
          format: val => Intl.NumberFormat().format(parseInt(val)),
          hideField: false,
          type: "dimension",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "failed",
          label: "Records Failed",
          field: "failed",
          format: val => Intl.NumberFormat().format(parseInt(val)),
          hideField: false,
          type: "dimension",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "select_query_duration",
          label: "Query Duration",
          field: "select_query_duration",
          format: val => val + "s",
          hideField: false,
          type: "dimension",
          sortable: true,
          sort: (a, b) => a - b
        },
        {
          name: "time_created",
          label: "Time Created",
          field: row => moment.utc(row.time_created).local(),
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          align: "left",
          type: "metric",
          metricType: "date"
        },
        {
          name: "time_updated",
          label: "Time Updated",
          field: row => moment.utc(row.time_updated).local(),
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          align: "left",
          type: "metric",
          metricType: "date"
        }
      ],
      history: [],
      startDate: moment().format("YYYY-MM-DD 00:00:00"),
      endDate: moment().format("YYYY-MM-DD 23:59:59"),
      productTypeId: 1,
      inventorySourceId: null,
      inventorySources: [],
      latestRecord: null,
      productTypeOptions: [
        { label: "Vehicles", value: 1, metricLabel: "Vehicles" },
        { label: "Jobs", value: 2, metricLabel: "Jobs" },
        { label: "All Products", value: 0, metricLabel: "Products" }
      ]
    };
  },
  watch: {
    productTypeId(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.inventorySourceId = null;
      }
      this.generateLineCharts();
    }
  },
  computed: {
    lastRefreshedFormatted() {
      return moment
        .utc(this.latestRecord["time_created"])
        .local()
        .format("lll");
    },
    latestSyncAccuracy() {
      return parseFloat(
        (
          100 -
          (Math.abs(
            this.latestRecord["es_products_active"] -
              this.latestRecord["db_products_active"]
          ) /
            this.latestRecord["db_products_active"]) *
            100
        ).toFixed(2)
      );
    },
    inventorySourceOptions() {
      const options = [
        {
          label: "All Inventory Sources",
          value: null
        }
      ];
      this.inventorySources.forEach(source => {
        options.push({
          label: source["name"],
          value: source["id"]
        });
      });
      return options;
    }
  },
  mounted() {
    this.generateLineCharts();
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
      this.generateLineCharts();
    },
    generateLineCharts() {
      this.loading = true;
      this.labels = [];
      this.series = [];

      axios({
        url:
          "https://api-v2.jumpfeed.com/internal/products/active-counts-history",
        params: {
          product_type_id: this.productTypeId,
          inventory_source_id: this.inventorySourceId,
          start_date: this.startDate,
          end_date: this.endDate
        }
      })
        .then(resp => {
          let history = resp.data.data["history"];
          this.latestRecord = resp.data.data["latest"];
          this.inventorySources = resp.data.data["inventory_sources"];

          let labels = [];
          let counts = {
            mysql: [],
            elastic: []
          };

          history.forEach(item => {
            counts.mysql.push(parseInt(item["db_products_active"]));
            counts.elastic.push(parseInt(item["es_products_active"]));
            labels.push(
              moment
                .utc(item["time_created"])
                .local()
                .format("M/D h:mma")
            );
          });

          this.history = resp.data.data["es_sync_log"];
          this.labels = labels;
          this.series = [{ data: counts.mysql }, { data: counts.elastic }];

          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
