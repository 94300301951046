<template>
  <div
    v-if="metrics.length > 0"
    class="flex q-pt-none"
  >
    <div
      v-for="(metric, i) in metrics"
      :key="'MetricsList_' + key + '_' + i"
      :class="{
        'q-pl-lg': i > 0
      }"
    >
      <Label :text="metric.label" underlined />
      <h4 class="flex items-center justify-start">
        <q-icon v-if="'icon' in metric" size="18px" :name="metric.icon.name" :color="metric.icon.color" :style="'color: ' + metric.icon.color" class="q-mr-xs" />
        {{ metric.value }}
      </h4>
    </div>
  </div>
</template>

<script>
import Label from "@/components/UI/Label";

export default {
  name: "MetricsList",
  components: { Label },
  props: {
    metrics: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      key: Math.floor(Math.random() * 10000)
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
