<template>
  <div
    class="label"
    :class="{ '--underlined': underlined, '--large': size === 'large' }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Label",
  props: {
    text: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "default"
    },
    underlined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.label {
  font-weight: 400;
  color: color(gray, medium-dark);
  font-size: 90% !important;

  &.--underlined {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 2px;
  }

  &.--large {
    font-size: 1.15em;
    line-height: 1.3em;
  }
}
</style>
