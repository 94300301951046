<template>
  <div :id="key + '_lineChart'" :style="{ width, height }" />
</template>

<script>
import * as echarts from "echarts";
import { EchartsJfTheme } from "@/assets/scripts/echarts-themes";

echarts.registerTheme("echarts-jf-theme", EchartsJfTheme);

export default {
  name: "LineChart",
  props: {
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "100%"
    },
    xAxisLabels: {
      type: Array,
      default: () => []
    },
    xAxisType: {
      type: String,
      default: "category"
    },
    dataSeries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      key: Math.floor(Math.random() * 10000)
    };
  },
  mounted() {
    let lineChart = echarts.init(
      document.getElementById(this.key + "_lineChart"),
      "echarts-jf-theme"
    );

    // Draw the chart
    lineChart.setOption({
      tooltip: {
        trigger: "axis"
      },
      grid: {
        left: 5,
        right: 0,
        bottom: 1,
        top: 5,
        containLabel: true
      },
      xAxis: {
        data: this.xAxisLabels.map((label, i) => {
          return {
            value: label,
            textStyle:
              i === 0
                ? { align: "left" }
                : i === this.xAxisLabels.length - 1
                ? { align: "right" }
                : {}
          };
        }),
        type: this.xAxisType,
        boundaryGap: false,
        axisLabel: {
          align: "left"
        }
      },
      yAxis: {
        position: "right",
        overlap: "truncate",
        scale: true
      },
      series: this.dataSeries.map(data => {
        return { ...data, type: "line" };
      })
    });

    window.addEventListener("resize", function() {
      lineChart.resize();
    });
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
