const EchartsJfTheme = {
  color: [
    "#1c5eca",
    "#76a3ec",
    "#16499d",
    "#246ce1",
    "#92b5f0",
    "#103470",
    "#1954b4",
    "#5b91e8",
    "#0c2a5a",
    "#407ee4",
    "#133f87"
  ],
  backgroundColor: "rgba(0, 0, 0, 0)",
  textStyle: {},
  title: {
    textStyle: {
      color: "#000000"
    },
    subtextStyle: {
      color: "#7c7c7c"
    }
  },
  line: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: "0",
    symbol: "emptyCircle",
    smooth: false
  },
  radar: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: "3",
    symbol: "emptyCircle",
    smooth: false
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: "#ccc"
    }
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  candlestick: {
    itemStyle: {
      color: "#c10015",
      color0: "#0f9d58",
      borderColor: "#c10015",
      borderColor0: "#0f9d58",
      borderWidth: "2"
    }
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    },
    lineStyle: {
      width: 1,
      color: "#e0e0e0"
    },
    symbolSize: "3",
    symbol: "emptyCircle",
    smooth: false,
    color: [
      "#1c5eca",
      "#76a3ec",
      "#16499d",
      "#246ce1",
      "#92b5f0",
      "#103470",
      "#1954b4",
      "#5b91e8",
      "#0c2a5a",
      "#407ee4",
      "#133f87"
    ],
    label: {
      color: "#fff"
    }
  },
  map: {
    itemStyle: {
      areaColor: "#eee",
      borderColor: "#444",
      borderWidth: 0.5
    },
    label: {
      color: "#000"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(255,215,0,0.8)",
        borderColor: "#444",
        borderWidth: 1
      },
      label: {
        color: "rgb(100,0,0)"
      }
    }
  },
  geo: {
    itemStyle: {
      areaColor: "#eee",
      borderColor: "#444",
      borderWidth: 0.5
    },
    label: {
      color: "#000"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(255,215,0,0.8)",
        borderColor: "#444",
        borderWidth: 1
      },
      label: {
        color: "rgb(100,0,0)"
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#e0e0e0"
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ["#E0E6F1"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e0e0e0"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e0e0e0"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ["#E0E6F1"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  toolbox: {
    iconStyle: {
      borderColor: "#7c7c7c"
    },
    emphasis: {
      iconStyle: {
        borderColor: "#373737"
      }
    }
  },
  legend: {
    textStyle: {
      color: "#7c7c7c"
    }
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: "#bbb",
        width: "1"
      },
      crossStyle: {
        color: "#7c7c7c",
        width: "1"
      }
    }
  },
  timeline: {
    lineStyle: {
      color: "#DAE1F5",
      width: 2
    },
    itemStyle: {
      color: "#A4B1D7",
      borderWidth: 1
    },
    controlStyle: {
      color: "#A4B1D7",
      borderColor: "#A4B1D7",
      borderWidth: 1
    },
    checkpointStyle: {
      color: "#8fd3e8",
      borderColor: "fff"
    },
    label: {
      color: "#A4B1D7"
    },
    emphasis: {
      itemStyle: {
        color: "#FFF"
      },
      controlStyle: {
        color: "#A4B1D7",
        borderColor: "#A4B1D7",
        borderWidth: 1
      },
      label: {
        color: "#A4B1D7"
      }
    }
  },
  visualMap: {
    color: ["#103470", "#407ee4", "#92b5f0"]
  },
  dataZoom: {
    handleSize: "undefined%",
    textStyle: {}
  },
  markPoint: {
    label: {
      color: "#eee"
    },
    emphasis: {
      label: {
        color: "#eee"
      }
    }
  }
};

const EchartsJfCascadingTheme = {
  color: [
    "#1c5eca",
    "#407ee4",
    "#76a3ec",
    "#92b5f0",
    "#5b91e8",
    "#246ce1",
    "#1954b4",
    "#133f87",
    "#16499d",
    "#103470",
    "#0c2a5a"
  ],
  backgroundColor: "rgba(0, 0, 0, 0)",
  textStyle: {},
  title: {
    textStyle: {
      color: "#000000"
    },
    subtextStyle: {
      color: "#7c7c7c"
    }
  },
  line: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: "0",
    symbol: "emptyCircle",
    smooth: false
  },
  radar: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: "3",
    symbol: "emptyCircle",
    smooth: false
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: "#ccc"
    }
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    }
  },
  candlestick: {
    itemStyle: {
      color: "#c10015",
      color0: "#0f9d58",
      borderColor: "#c10015",
      borderColor0: "#0f9d58",
      borderWidth: "2"
    }
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc"
    },
    lineStyle: {
      width: 1,
      color: "#e0e0e0"
    },
    symbolSize: "3",
    symbol: "emptyCircle",
    smooth: false,
    color: [
      "#1c5eca",
      "#407ee4",
      "#76a3ec",
      "#92b5f0",
      "#5b91e8",
      "#246ce1",
      "#1954b4",
      "#133f87",
      "#16499d",
      "#103470",
      "#0c2a5a"
    ],
    label: {
      color: "#fff"
    }
  },
  map: {
    itemStyle: {
      areaColor: "#eee",
      borderColor: "#444",
      borderWidth: 0.5
    },
    label: {
      color: "#000"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(255,215,0,0.8)",
        borderColor: "#444",
        borderWidth: 1
      },
      label: {
        color: "rgb(100,0,0)"
      }
    }
  },
  geo: {
    itemStyle: {
      areaColor: "#eee",
      borderColor: "#444",
      borderWidth: 0.5
    },
    label: {
      color: "#000"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(255,215,0,0.8)",
        borderColor: "#444",
        borderWidth: 1
      },
      label: {
        color: "rgb(100,0,0)"
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#e0e0e0"
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ["#E0E6F1"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e0e0e0"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#6E7079"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e0e0e0"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: "#7c7c7c"
      }
    },
    axisLabel: {
      show: true,
      color: "#7c7c7c"
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ["#E0E6F1"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"]
      }
    }
  },
  toolbox: {
    iconStyle: {
      borderColor: "#7c7c7c"
    },
    emphasis: {
      iconStyle: {
        borderColor: "#373737"
      }
    }
  },
  legend: {
    textStyle: {
      color: "#7c7c7c"
    }
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: "#bbb",
        width: "1"
      },
      crossStyle: {
        color: "#7c7c7c",
        width: "1"
      }
    }
  },
  timeline: {
    lineStyle: {
      color: "#DAE1F5",
      width: 2
    },
    itemStyle: {
      color: "#A4B1D7",
      borderWidth: 1
    },
    controlStyle: {
      color: "#A4B1D7",
      borderColor: "#A4B1D7",
      borderWidth: 1
    },
    checkpointStyle: {
      color: "#8fd3e8",
      borderColor: "fff"
    },
    label: {
      color: "#A4B1D7"
    },
    emphasis: {
      itemStyle: {
        color: "#FFF"
      },
      controlStyle: {
        color: "#A4B1D7",
        borderColor: "#A4B1D7",
        borderWidth: 1
      },
      label: {
        color: "#A4B1D7"
      }
    }
  },
  visualMap: {
    color: ["#103470", "#407ee4", "#92b5f0"]
  },
  dataZoom: {
    handleSize: "undefined%",
    textStyle: {}
  },
  markPoint: {
    label: {
      color: "#eee"
    },
    emphasis: {
      label: {
        color: "#eee"
      }
    }
  }
};

export { EchartsJfTheme, EchartsJfCascadingTheme };
